import React, {Fragment, FunctionComponent as FC} from "react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {isAndroid, isIos} from "../hooks/mobile";
import Button, {Icon} from "./button";

type GradientProp = {
  $gradient: boolean;
};

const StyledButton = styled.button<GradientProp>`
  ${({$gradient}) =>
    $gradient &&
    `
      background-image: linear-gradient(to right, #3ab1e6 , #b63e8f 90%);
  `}
`;

type TStampValidation = {
  gradient?: boolean;
  className?: string;
  icon?: Icon;
  btnText?: string;
};

const StampValidation: FC<TStampValidation> = ({
  gradient = false,
  className = "",
  icon = Icon.Beenhere,
  btnText = "btn.download.app"
}) => {
  var isMobileOs = isAndroid() || isIos();

  if (isMobileOs) {
    const toRehubUrl = isIos() ? "https://d2.vc/bm4/website/app/ios" : "https://d2.vc/bm4/website/store";
    return <div className="text-center text-md-right">
      <Button
        to={toRehubUrl}
        icon={icon}
        className={className}
        external={true}
        gradient={gradient}
      >
        <FormattedMessage id={btnText} />
      </Button>
    </div>;
  }

  // Website or unknown device display both download buttons.
  return (
    <Fragment>
      <div className="text-center text-md-right">
        <Button
          to="https://d2.vc/bm4/website/store"
          className={className + " mb-3"}
          external={true}
          gradient={gradient}
        >
          <FormattedMessage id="btn.download.app.android" />
        </Button>
      </div>
      <div className="text-center text-md-right">
        <Button
          to="https://d2.vc/bm4/website/app/ios"
          className={className}
          external={true}
          gradient={gradient}
        >
          <FormattedMessage id="btn.download.app.ios" />
        </Button>
      </div>
    </Fragment>
  );
};

export default StampValidation;
