import React, {FunctionComponent as FC} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../button";
import Section from "../section";

const FAQLink: FC = () => {
  const intl = useIntl();

  return (
    <Section
      title={intl.formatMessage({id: "faq"})}
      className="my-5"
      margin={false}
    >
      <p className="mt-3 mb-0">
        <FormattedMessage id="faq.link.text" />
      </p>
      <p>
        <FormattedMessage id="faq.link.text2" />
      </p>
      <div className="row">
        <div className="col-12">
          <Button to="/faq#content" className="float-right">
            <FormattedMessage id="faq" />
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default FAQLink;
