import React, {FunctionComponent as FC} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {StringParam, useQueryParam} from "use-query-params";
import {StampImg} from "..";
import Button from "../../components/button";
import Error from "../../components/error";
import {CtaButton} from "../../components/heroarea/cta-button";
import {
  BackgroundImage,
  Content,
  ImageSizedContainer
} from "../../components/heroarea/single-image";
import StampValidation from "../../components/stampvalidation";
import {Helmet} from "react-helmet";

const EasterEggDetails: FC = () => {
  // EasterEggId maps to EasterEgg DocumentId in Firestore
  const [easterEggId] = useQueryParam("easterEggId", StringParam);
  const intl = useIntl();

  // Prevent SSR with React Rehydration rendering error: https://github.com/gatsbyjs/gatsby/discussions/17914
  if (typeof window === "undefined") {
    return null;
  }

  if (!easterEggId) {
    return (
      <Error errorMsg={"No easter egg id provided."} error showButton={false} />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {id: "title.easterEggs.details"},
            {easterEggId: easterEggId}
          )}
        </title>
      </Helmet>
      <BackgroundImage
        imageUrl="/easter-egg-header.png"
        role="img"
        aria-label="Easter Egg Stamps"
      />
      <ImageSizedContainer>
        <Content>
          <CtaButton
            url="#what-is-an-easter-egg"
            className="text-uppercase"
            caption={intl.formatMessage({id: "easter-egg.cta"})}
          />
        </Content>
      </ImageSizedContainer>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 col-md-4">
            <StampImg className="mb-4" src="/easter-egg.png" alt="Easter egg" />
          </div>
          <div className="col-12 col-md-8">
            <h1
              id="what-is-an-easter-egg"
              className="rfs-title font-weight-normal"
            >
              <FormattedMessage id="easter-egg.title" />
            </h1>
            <p className="rfs-large">
              <FormattedMessage id="easter-egg.content1" />
            </p>
            <p className="rfs-large">
              <FormattedMessage id="easter-egg.content2" />
            </p>
            <div className="d-flex justify-content-center justify-content-md-end mt-3 mb-5">
              <div className="text-center " style={{maxWidth: 350 + "px"}}>
                {typeof window !== "undefined" && (
                  <StampValidation
                    className="btn-sm mb-3 px-3 text-uppercase d-block"
                    gradient
                    icon={null}
                    btnText="easter-egg.btn.app"
                  />
                )}
                <Button
                  to={intl.formatMessage({id: "link.shop.marie"})}
                  className="btn-sm text-uppercase d-block"
                  external
                  gradient
                >
                  <FormattedMessage id="main.stamp.buy" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EasterEggDetails;
