import React, {FunctionComponent as FC} from "react";
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import Button, {Icon} from "../button";
// @ts-ignore
import CrossLogo from "./error.svg";

const CrossImg = styled.img`
  max-height: 264px;
  display: block;
`;

const Text = styled.h2`
  text-align: center;
  max-width: 300px;
  margin: auto;
  font-size: 20px;
  font-weight: normal;
`;

type ErrorProps = {
  error: any;
  errorMsg?: string;
  showButton?: boolean;
};

const Error: FC<ErrorProps> = ({error, errorMsg, showButton = true}) => {
  return (
    <>
      <FormattedMessage id="title.error">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div className="container mb-3 my-md-5">
        <div className="row flex-column justify-content-center align-items-center">
          <CrossImg src={CrossLogo} alt="" />
          <Text className="mb-3 mb-md-5">
            {errorMsg ? (
              errorMsg
            ) : (
              <FormattedMessage id="error.no.valid.stamp" />
            )}
          </Text>
          {showButton ? (
            <Button to="/" icon={Icon.Beenhere}>
              <FormattedMessage id="btn.verifiable.stamps" />
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Error;
