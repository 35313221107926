import React, {useContext, useEffect} from "react";
import {Helmet} from "react-helmet";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import Button from "../components/button";
import Divider from "../components/divider";
import FAQLink from "../components/faqs/faq-link";
import HeroArea from "../components/heroarea";
import StampValidation from "../components/stampvalidation";
import {RootContext} from "../root";
import {Markdown} from "../components/markdown";

const StyledOl = styled.ol`
  padding-left: 25px;
`;

export const StampImg = styled.img`
  width: 100%;
  max-height: 230px;
  object-fit: contain;
`;

export default () => {
  const context = useContext(RootContext);
  const intl = useIntl();

  useEffect(() => {
    if (context) context.setError(null);
  }, [context]);

  return (
    <>
      <FormattedMessage id="title.index">
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Briefmarke 4.0: Blockchain-Technologie aus dem Fürstentum Liechtenstein. Fälschungssicher, interaktiv und spannend. Jetzt entdecken!"
            />
          </Helmet>
        )}
      </FormattedMessage>
      <HeroArea />
      <div className="container">
        <div className="row my-5">
          <div className="col-12 col-md-4">
            <StampImg
              className="mb-4"
              src={"scan-stamp.png"}
              alt="replace this"
            />
          </div>
          <div className="col-12 col-md-8">
            <h1
              id="what-is-the-stamp-40"
              className="rfs-title font-weight-normal"
            >
              <FormattedMessage id="main.stamp.title" />
            </h1>
            <p className="rfs-large">
              <Markdown
                content={intl.formatMessage({
                  id: "main.stamp.content.markdown"
                })}
              ></Markdown>
            </p>
            <div className="text-center text-md-right my-3">
              <Button
                to={intl.formatMessage({id: "link.shop"})}
                className="btn-sm mb-3 "
                external
                gradient
              >
                <FormattedMessage id="main.stamp.buy" />
              </Button>
              {typeof window !== "undefined" && (
                <StampValidation className="btn-sm" gradient icon={null} />
              )}
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-12 col-md-4">
            <Divider className="mb-4 d-md-none" margin={false} />
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube-nocookie.com/embed/LQPQuiB7xrg"
              title="Briefmarke 4:0 Echte Blockchain Technologie aus dem Fürstentum Liechtenstein"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="col-12 col-md-8 p-">
            <Divider
              className="mb-4 d-none d-sm-none d-md-block"
              margin={false}
            />
            <h1 className="mt-3 rfs-title font-weight-normal">
              <FormattedMessage id="main.blockchain.title" />
            </h1>
            <p className="rfs-large font-weight-bold">
              <FormattedMessage id="main.stamp.instructions" />
            </p>
            <StyledOl>
              <li className="rfs-large">
                <FormattedMessage id="main.blockchain.content" />
              </li>
              <li className="rfs-large">
                <FormattedMessage id="main.blockchain.content2" />
              </li>
            </StyledOl>
          </div>
        </div>
        {/*Newsletter section*/}
        <div className="row my-5">
          <div className="col-12 col-md-4">
            <Divider className="mb-4 d-md-none" margin={false} />
            <StampImg
              className="mb-4"
              src={"newsletter_subscribe.png"}
              alt="Newsletter subscribe"
            />
          </div>
          <div className="col-12 col-md-8 p-">
            <Divider
              className="mb-4 d-none d-sm-none d-md-block"
              margin={false}
            />
            <h1 className="mt-3 rfs-title font-weight-normal">
              <FormattedMessage id="main.newsletter.title" />
            </h1>

            <p className="rfs-large">
              <FormattedMessage id="main.newsletter.content" />
            </p>
            <div className="text-center text-md-right my-3">
              <Button
                to={intl.formatMessage({id: "link.newsletter"})}
                className="btn-sm mb-3 "
                external
                gradient
              >
                <FormattedMessage id="main.newsletter.subscribe.button" />
              </Button>
            </div>
          </div>
        </div>
        <FAQLink />
      </div>
    </>
  );
};
